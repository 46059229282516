<template>
  <div>
    <!-- <pre>{{ panelObj }}</pre> -->
    <div v-if="activePanel === 'ginner' || activePanel === 'grower'" fluid>
      <div v-if="panelObj.ginnerGrowerLoader" class="text-align: center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <br/>
      </div>
      <b-card v-else-if="panelObj.ginnerGrowerMessage !== ''">
        <div class="text-danger">
          <div class="message-body-center">
            <p class="mb-0 h5"><i class="ri-alert-fill mr-2"></i>{{ panelObj.ginnerGrowerMessage }}</p>
          </div>
        </div>
      </b-card>
      <div v-else>
        <welcome-dashboard :user="authUser"/>
      </div>
    </div>
    <div v-else-if="activePanel === 'irrigation'" fluid>
      <profile-status-msg :preConditionLoader="panelObj.irriUserLoader" :profileStatus="panelObj.irriUserStatus" :profile-url="'/irrigation/my-profile'" :inForm="false"/>
      <div v-if="panelObj.irriUserStatus > 3">
        <div>
          <welcome-dashboard :user="authUser"/>
        </div>
      </div>
    </div>
    <div v-else>
      <welcome-dashboard :user="authUser"/>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farmerInfo } from '../api/routes'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import WelcomeDashboard from '@/modules/external-user-service/irrigation/components/WelcomeDashboard'

export default {
  components: { ProfileStatusMsg, WelcomeDashboard },
  data () {
    return {
      loadingState: false,
      overlay: true,
      user: {},
      profileStatus: 0,
      approveStatus: ''
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser',
      panelObj: 'ExternalUserIrrigation/panelObj'
    }),
    activePanel () {
      return this.$store.state.ExternalUserIrrigation.activePanel
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  mounted () {
    core.index()
    // this.loadData()
    // this.loadSchedule()
  },
  created () {
    if (this.activePanel === 'irrigation') {
      this.checkUser()
    }
  },
  methods: {
    checkUser () {
      this.loadingState = true
      RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo + '?with_data=' + false).then(response => {
        if (response.success) {
          this.overlay = false
          this.user = response.data
          this.profileStatus = response.data.status
        }
        this.profileStatus = 0
        this.loadingState = false
      })
    }
  }
}
</script>

<style scoped>
/* .min-height {
  height: 100vh;
}
.my-card {
  min-height: 100vh;
  background-image: url("../../../../assets/images/login/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.t-shadow{
  text-shadow: 1px 1px 1px #000;
} */
</style>
