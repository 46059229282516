<template>
    <div>
        <b-container fluid>
          <b-row>
              <b-col>
                  <b-card class="my-card">
                        <h3 style="color: #4D56D6;font-size: 24px">
                            {{ $t('externalUserIrrigation.hello')}}
                            <span style="font-weight: 600;" v-if="this.$i18n.locale === 'bn'">{{ user.name_bn }}!</span>
                            <span style="font-weight: 600;" v-if="this.$i18n.locale === 'en'">{{ user.name }}!</span>
                        </h3>
                        <!-- <p v-if="this.$i18n.locale === 'bn'">
                            <span>কৃষি মন্ত্রণালয়</span> এর
                            <span>ইন্টিগ্রেটেড ডিজিটাল পরিষেবা ডেলিভারি প্ল্যাটফর্ম</span> এ আপনাকে স্বাগতম
                        </p>
                        <p v-if="this.$i18n.locale === 'en'">
                            Welcome to <span>Integrated Digital Service Delivery Platform Service</span> of
                            <span>Ministry of Agriculture</span>
                        </p> -->
                        <h5 class="mt-3" style="color: #b52929">
                            <i data-icon="i" class="icon align-middle"></i> <span>{{ $t('externalUserIrrigation.your_all_service_here') }}</span>
                        </h5>
                  </b-card>
              </b-col>
          </b-row>
      </b-container>
    </div>
</template>

<script>
export default {
  props: ['user']
}
</script>
